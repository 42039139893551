.citation {
  padding: 4rem 10rem;

  p {
    display: block;
    margin: 0 auto;
    max-width: 1300px;
    font-size: 1.4rem;
    text-align: center;
    color: $blue;

    &:first-child {
      font-style: italic;
    }
  }

  &--blue {
    background-color: $blue;

    p {
      color: #fff;
    }
  }

  + .citation {
    margin-top: -3rem;
  }

  &__signature {
    margin-top: 0.5rem;
    font-weight: 600;
    font-size: 1.2rem;
    text-align: right;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 56em) {
  .citation {
    padding: 2rem;

    p {
      font-size: 1.4rem;
    }

    + .citation {
      margin-top: 0;
    }
  }
}
