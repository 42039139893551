@font-face {
  font-family: "Suisse Intl";
  src: url("./suisse/SuisseIntl-Regular.priority.woff2") format("woff2"),
    url("./suisse/SuisseIntl-Regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Suisse Intl";
  src: url("/suisse/SuisseIntl-Medium.priority.woff2") format("woff2"),
    url("./suisse/SuisseIntl-Medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Suisse Intl";
  src: url("/suisse/SuisseIntl-Bold.priority.woff2") format("woff2"),
    url("./suisse/SuisseIntl-Bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Suisse Intl";
  src: url("/suisse/SuisseIntl-Light.woff2") format("woff2"),
    url("./suisse/SuisseIntl-Light.woff") format("woff");
  font-weight: 200;
}

$fontFamily: "Suisse Intl", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;

$regular: 400;
$semiBold: 500;
$bold: 700;
$thin: 200;

$tinySize: 0.7rem;
$extraSmallSize: 0.8rem;
$smallSize: 0.9rem;
$regularSize: 1rem;
$mediumSize: 1.1rem;
$largeSize: 1.2rem;
$veryLargeSize: 1.4rem;
$extraLargeSize: 2rem;
$giantSize: 2.5rem;

$mediumIconSize: 1.6rem;
$smallIconSize: 1.2rem;

/* export variables used in `Typography.story.js` */
:export {
  family-roboto: $fontFamily;
  weight-regular: $regular;
  weight-semibold: $semiBold;
  weight-bold: $bold;
  size-tinysize: $tinySize;
  size-extrasmallsize: $extraSmallSize;
  size-smallsize: $smallSize;
  size-regularsize: $regularSize;
  size-mediumsize: $mediumSize;
  size-extralargesize: $extraLargeSize;
  size-giantsize: $giantSize;
  size-mediumiconsize: $mediumIconSize;
  size-smalliconsize: $smallIconSize;
}

html {
  font-size: 0.85em;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size-adjust: 100%;
}

body {
  background: $backgroundColor;
  color: $fontColor;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: $regular;
  line-height: 1.7;
  font-display: fallback;
}

.inverted {
  color: $backgroundColor;
}

.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}

@media print {
  html {
    font-size: 10pt;
  }
}

@import "~theme/components/atoms/Typography/Body/Body";
@import "~theme/components/atoms/Typography/Heading/Heading";
@import "~theme/components/atoms/Typography/Link/Link";
@import "~theme/components/atoms/Typography/Link/Anchor";
@import "~theme/components/atoms/Typography/Price/Price";

.bold {
  font-weight: 500;
}
.title-blue {
  color: $blue;
}
.title-underline {
  padding-bottom: 2rem;
}
.title-underline::after {
  content: "";
  display: block;
  height: 1px;
  width: 373px;
  background-color: $blue;
  margin-top: 1rem;
}
.title--thin {
  font-weight: 300;
}
.h2--strong {
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
}
.text--important {
  color: $blue;
}

.h1,
h1 {
  font-size: 3rem;
}

.link--underline {
  text-decoration: underline;
}
.link--underline:hover {
  color: $blue;
}
.page-content__content h2,
.page-content__content h3,
.page-content__content h4 {
  font-weight: 300;
}

.products-push__title .h1,
.reviews .h1 {
  @extend .title-blue;
}

.link--underline {
  cursor: pointer;
}

.cart-title .h1 {
  @extend .title-underline;
}

@media screen and (max-width: $menuBreakpoint) {
  .title-underline::after {
    width: 200px;
  }
}

@media screen and (min-width: $tabletBreakpoint) {
  .cms-page {
    &:not(.cms-page--home) {
      .h1,
      h1 {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
}

.page-content--form {
  .h1 {
    @extend .title-underline;
  }
}

div p .link {
  text-decoration: none;
}

.cms-page {
  &--home {
    .wysiwyg > p {
      margin-top: 0;
    }
  }
}

.cms-page {
  &:not(.cms-page--home):not(.cms-page--welcome) {
    .wysiwyg {
      padding: 0 5rem;

      > h2 {
        @extend .title-underline;
        @extend .h1;
        font-weight: 600;
        font-size: 2rem;
      }

      > h3 {
        @extend .title-blue;
        @extend .h2;
        font-weight: 300;
        margin-top: 2em;
      }

      > h4 {
        @extend .title-blue;
        @extend .h3;
        font-weight: 300;
        margin-top: 2em;
      }
    }

    .highlighted-content {
      padding: 0;

      .responsive-image {
        --image-ratio: auto !important;
      }
    }
  }
}
