.product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 $boxSizeMargin;

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: $boxSizeMargin * 3 / 2 0;
  }

  &__price {
    font-size: $extraLargeSize;
  }

  &__gallery {
    width: 500px;
    max-width: 100%;
    align-self: flex-start;
    margin: 0 auto;

    &__main {
      position: relative;
      width: 100%;
      margin-bottom: $boxSizeMargin;
    }

    &__slider {
      border-top: 1px solid $divider;
      padding: 1em 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 1em;

      &--prev,
      &--next,
      &__image {
        padding: 0 0.5em;
      }
      &__image {
        position: relative;
        width: 100%;
        max-height: 10em;
      }
      &--prev,
      &--next {
        position: absolute;
      }
      &--prev {
        right: 100%;
      }
      &--next {
        left: 100%;
      }
    }
  }

  &__synthesis {
    margin: auto;
    max-width: 400px;
    background: $white;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba($shade05, 0.35);
    padding: $boxSizeMargin;
  }

  &__add-to-cart {
    margin: 2em 0;
  }

  &__out-of-stock {
    font-size: $mediumSize;
    color: $shade05;
  }
}

@media screen and (min-width: #{$menuBreakpoint}) {
  .product {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    &__gallery,
    &__synthesis {
      margin: 2em;
    }
    &__gallery {
      flex: 1.2;
      min-width: 250px;
    }
    &__synthesis {
      min-width: 250px;
      width: 33%;
      flex: 1.2;
      flex: 1;
      .configurator {
        max-width: 100%;
      }
    }
  }
}

.product-name::after {
  content: "";
  display: block;
  height: 1px;
  width: 25rem;
  background-color: $blue;
}

.product-name__subinfo {
  display: flex;
  align-items: baseline;
}

.product-name__name {
  .h1 {
    line-height: 1;

    span {
      font-size: 1.5rem;
    }
  }
}
.product-name {
  &__latin-name {
    font-weight: 200;
    font-size: 1.5rem;
    font-style: italic;
  }

  &__cas {
    font-size: 1.5rem;
    font-weight: 300;
    font-style: normal;
    margin-left: 1rem;
  }
}
.product-name__avis {
  display: flex;
  margin-left: 1rem;
}
.avis__number {
  margin-left: 0.5rem;
}
.avis__stars {
  display: flex;
}
.star--svg {
  width: 20px;
  height: 18px;
  fill: #dbdbdb;
}
.star--checked svg {
  fill: $blue;
}
.product-labels {
  margin-top: 1rem;
  display: flex;
}
.product-labels__img {
  max-width: 80px;
}
.product-labels__img img {
  width: 100%;
  height: auto;
}
.product-rassurances {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #afafaf;
  border-bottom: 1px solid #afafaf;
  padding: 1rem 4rem;
}
.product-rassurances__rassurance {
  text-align: center;
  margin: 0 1.5rem;
}
.product-rassurances__rassurance div {
  font-weight: 200;
  font-size: 0.8rem;
  text-transform: uppercase;
}
.product-rassurances__rassurance img {
  max-width: 4.2rem;
  height: auto;
}
.form-item__input > .product-price {
  width: auto;
  flex: 2;
}
.form-item__link {
  margin-top: -1.4rem;
}
.price {
  font-weight: 900;
}
.add-to-cart__expedition {
  text-transform: uppercase;
  margin-top: 1rem;
}

@media screen and (min-width: $desktopBreakpoint) {
  .layer-products__element--4 {
    width: 25%;
  }
}

@media screen and (max-width: $desktopBreakpoint) {
  .products-push .layer-products__element--4 {
    width: 100%;
  }

  .configurator {
    > .grid > .cell > .grid > .cell {
      width: 50%;
      min-width: 50%;
    }
  }
}

.product-overview__latin-name {
  color: gray;
  margin-top: -0.4rem;
  font-style: italic;
}
.schema-contenants {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.schema-contenants__contenant {
  max-width: 100px;
  margin: 0 0.2rem;
}
.schema-contenants__contenant svg {
  fill: none;
  stroke: #002e52;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 3px;
}
.contenant--selected svg {
  fill: $blue;
}
.contenant__5g {
  width: 12px;
}
.contenant__50g {
  width: 20px;
}
.contenant__200g {
  width: 24px;
}
.contenant__1kg {
  width: 29px;
}
.contenant__5kg {
  width: 60px;
}
.contenant__25kg {
  width: 87px;
}
.contenant__180kg {
  width: 102px;
}

.product-view__gallery .product-gallery {
  display: block;

  &__aside {
    max-width: 100%;
    padding-right: 0;
  }
}

.items-slider--vertical .items-slider__item {
  max-height: 50px;
  margin: 0.5rem 0;
}

.items-slider--vertical .items-slider__element {
  padding: 0;
}

.product-overview {
  .product-item__actions {
    position: static;
  }

  .product-overview__name {
    font-weight: 600;
    line-height: 1.4;

    span + span {
      display: block;
      margin-top: 0;
      margin-bottom: 1rem;
      font-weight: 400;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;

      .button {
        background-color: #eee;
      }
    }
  }

  .button {
    transition: all 0.5s;
  }
}

.product-page {
  .product-reviews-synthesis {
    margin: 0 0.5rem;
  }
}

.add-to-cart__quantity {
  form .form-item__label label {
    margin-left: 0;
  }
}

.product-added__price {
  .product-price__price,
  .product-price__regular {
    font-size: 1.1rem;
  }
}

.configurator {
  .product-price {
    margin-bottom: 0.6rem;

    &__price {
      text-align: right;
    }

    &:first-child {
      margin-top: 2.8rem;
    }
  }

  form .form-item__label label {
    margin-left: 0;
  }

  > .grid > .cell {
    padding: 0;

    &:first-child {
      padding-right: 1rem;
    }

    &:last-child {
      padding-left: 1rem;
    }
  }
}

.product-view {
  .product-name {
    &__main {
      align-items: center;
    }

    &__id {
      align-self: center;
    }
  }
}

@media screen and (max-width: $desktopBreakpoint) {
  .configurator > .grid > .cell + .cell {
    display: none;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .product-view__actions {
    left: 1.8rem;
    right: 0;
  }

  form .add-to-cart__quantity .form-item__label label {
    margin-left: 0;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .layer-products {
    .layer-products__element {
      width: 100%;
    }
  }
}

.add-to-cart--out-of-stock {
  color: $red;
}

.subscribe-to-in-stock-alert {
  .button {
    display: flex;
    margin: 20px 0;
  }
}

.preconditionned__container {
  border: 1px solid $blue;
  padding: 1rem;
  margin-top: 2.4rem;

  p {
    margin-bottom: 1.5rem;
    display: block;
    margin-top: 0;  
  }
}

.preconditionned_bottles {
  svg {
    margin: 0 -2px;
    display: inline-block;

    &:nth-child(2n) {
      transform-origin: center;
      transform: rotate(180deg) translateY(10px);
    }
  }
}