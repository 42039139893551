* {
  box-sizing: border-box;
  font-family: "Suisse Intl";
  color: #262626;
}

.body {
  max-width: 100vw;
  overflow-x: hidden;
}

.hidden {
  display: none !important;
}

.wrapper {
  position: relative;
  max-width: 100vw;
  overflow: hidden;

  &--error {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .header__logo {
      text-align: center;

      img {
        width: 100%;
        max-width: 400px;
        display: block;
        margin: 0 auto;
      }
    }

    .h1 {
      font-size: 2.6rem;
    }

    p {
      font-size: 1.8rem;
      text-align: center;
    }
  }
}
.refreshing {
  cursor: wait;
  opacity: 0.2;
}
@mixin container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: $smallContainerWidth + $boxSizeMargin * 3;
  padding-left: $boxSizeMargin * 1.5;
  padding-right: $boxSizeMargin * 1.5;
}
.container {
  @include container;
}
.container--mobile-only {
  @include container;
}
@media screen and (min-width: $tabletBreakpoint) {
  .container--tablet-only {
    @include container;
  }
}
@media screen and (min-width: $menuBreakpoint) {
  .container {
    max-width: $containerWidth + $boxSizeMargin * 3;
  }
  .container--mobile-only {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .container--tablet-only {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .container--desktop-only {
    @include container;
    max-width: $containerWidth + $boxSizeMargin * 3;
  }
}
.wrapper > .container {
  margin: $boxSizeMargin auto;
}
.main-content {
  position: relative;
  outline: none;
  z-index: 1;
}

.page-content {
  padding-top: $boxSizeMargin * 2;
  padding-bottom: $boxSizeMargin * 2;

  &--with-image-top-mobile {
    padding-top: 0;
  }
  &--simple {
    padding-top: $boxSizeMargin * 2;
    padding-bottom: $boxSizeMargin * 4;
  }
  &--error {
    color: $red;
  }
  @media screen and (min-width: $menuBreakpoint) {
    padding-top: $boxSizeMargin * 2;
    padding-bottom: $boxSizeMargin * 4;
    &--simple {
      padding-top: $boxSizeMargin * 6;
      padding-bottom: $boxSizeMargin * 8;
    }
  }
}

.without-outer-margin {
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
}

@media print {
  .container {
    max-width: unset;
    padding: 0;
  }

  .page-content {
    padding: 0;
  }
}

@import "~./Header/Header";
@import "~./Footer/Footer";

* {
  font-family: "Suisse Intl";
  color: #262626;
}
iframe {
  max-width: 100%;
}
.no-padding-top {
  padding-top: 0 !important;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}
.no-margin-top {
  margin-top: 0;
}
.background-blue {
  background-color: $blue;
}
.background-blue * {
  color: $white;
}
.container {
  max-width: 120.6rem;
}
.container--desktop-only {
  max-width: 120.6rem;
}
.spacer--border {
  border-color: #afafaf;
}

.transparent-background {
  background-color: transparent;
}
.product-gallery {
  display: block;
}
.items-slider__slider {
  margin: 0;
}
.items-slider--vertical .items-slider__item {
  flex-direction: row;
  justify-content: center;
}
.items-slider--vertical .items-slider__element {
  max-width: 15%;
}
.tabs {
  padding-bottom: 2rem;
}
.tabs__head::after,
.tabs__head::before {
  content: none;
}
.tabs__controls {
  text-align: left;
  border-bottom: 1px solid #afafaf;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}
.feature-list {
  margin: -2.2rem 0;
}
.feature-list__element {
  padding: 1.2rem 0;
}
.product-item__actions {
  position: static;
  margin-top: 1.5rem;
}
.expandable-card {
  padding: 0rem;
  border: none;
}
.expandable-card__header {
  text-transform: none;
  font-weight: 400;
  letter-spacing: 0;
}
.layer-header__total {
  color: $darkgrey;

  span {
    display: inline-block;
    margin-right: 0.3rem;
  }
}
.paginator {
  margin: -0.6rem 2rem;
}
.paginator__page--next,
.paginator__page--previous {
  flex: 0;
}
.paginator__page--disabled .paginator__link {
  display: none;
}
.paginator__page--active {
  border: 1px solid $black;
}
.paginator__link:focus,
.paginator__link:hover {
  text-decoration: none;
}
.paginator__link {
  font-weight: 400;
  padding: 0.1rem 0.8rem;
}
.paginator__page--active .paginator__link {
  text-decoration: none;
  font-weight: 400;
}

.listing-header .grid {
  align-items: center;
}
.listing-header__description {
  padding: 1rem;
}
.layer-header__pagination {
  flex: 1;
  display: flex;
  align-items: center;
}
.layer-header__pagination .paginator {
  margin: 0;
}
.filter-title {
  border-bottom: 1px solid #d0d0d0;
  padding-bottom: 0.8rem;
  margin-bottom: 2rem;
}
.filter-title__title {
  text-transform: uppercase;
  font-weight: 500;
}
.header__mobile-expanded {
  display: none;
}
@media screen and (min-width: $menuBreakpoint) {
  .sticky-element {
    position: -webkit-sticky;
    position: sticky;
    top: 8rem;
  }
  .layer-page__main {
    width: 84%;
  }
  .layer-page__aside {
    width: 24%;
    min-width: 41ch;
  }
  .layer-header {
    padding: 0 1rem 0 2rem;
    margin: 0;
  }
  .layer-products__element {
    padding: 3.2rem;
  }
}

@media screen and (max-width: $menuBreakpoint) {
  .container {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }
  .no-padding-top--mobile {
    padding-top: 0 !important;
  }
  .less-padding-top--mobile {
    padding-top: 1rem !important;
  }
  .less-padding-mobile {
    padding: 1rem 0;
  }
  .product-name__subinfo {
    display: block;
  }
  .product-name__avis {
    margin-left: 0;
    margin-bottom: 1.5rem;
  }
  .product-rassurances__rassurance img {
    max-width: 2.2rem;
  }
  .product-rassurances__rassurance div {
    font-size: 0.7rem;
  }

  .add-to-cart__expedition {
    margin-top: 0.8rem;
    margin-left: -0.4rem;
  }
  .layer-page__aside {
    display: block;
    background-color: $lightgrey;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .filter-title {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
    padding-left: 1.4rem;
    position: relative;
  }
  .filter-title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 7px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 7px solid #343434;
    border-bottom: 5px solid transparent;
  }
  .layer-page__aside form {
    display: none;
  }
  .layer-page__aside--expanded form {
    display: block;
  }
  .layer-page__aside--expanded .filter-title {
    margin-bottom: 1rem;
  }
  .layer-page__aside--expanded .filter-title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 7px;
    width: 0;
    height: 0;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-top: 7px solid #343434;
  }
  .layer-header {
    margin: 0;
    flex-wrap: wrap;
  }
  .layer-header__actions {
    flex: 1;
    flex-basis: 100%;
    padding: 0;
  }
  .layer-sorting--standalone {
    display: block;
    margin: 0 0 1rem 0;
  }
  .layer-sorting__element {
    padding: 0;
  }
  .layer-sorting__element .form-item__input {
    width: 100%;
  }
  .layer-header__pagination {
    order: 2;
    flex: 1;
    flex-basis: 100%;
    justify-content: space-between;
  }
  .layer-products {
    margin: -0.7rem;
  }
  .layer-products__element {
    padding: 0.6rem;
  }
  .paginator {
    margin: 0;
  }
  .listing-header__description {
    padding-top: 0;
    text-align: center;
  }
  .listing-header__title {
    margin: 0;
  }

  .tabs__head::after,
  .tabs__head::before {
    content: "";
  }
  .tabs__controls {
    margin: -0.6rem -1.5rem;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
  }
  .main-content {
    padding-top: 5em;
  }
}
@media screen and (max-width: $tabletBreakpoint) {
  .items-slider.items-slider--vertical {
    margin-top: -5.1rem;
  }
  .items-slider--vertical .items-slider__element {
    max-width: 17%;
    padding: 0.5rem;
  }

  .tabs__controls {
    overflow: hidden;
    max-width: 100%;
    margin: 0;
    white-space: normal;

    .button {
      width: 100%;
      padding: 0.5rem 0.2rem;
    }
  }

  .certification {
    max-width: 50%;

    img {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
    }
  }
}
@media screen and (max-width: $mobileBreakpoint) {
  .layer-products__element {
    width: 100%;
  }

  .add-to-cart__main {
    align-items: flex-end;
  }

  .product-item__actions button {
    padding: 0.3rem;
  }

  .add-to-cart__quantity {
    padding: 0.2rem;
  }

  .modal-multiple-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    a {
      display: block;
      margin-top: 1rem;
    }
  }
}

.listing-header {
  width: 100%;
  overflow: hidden;
}

.layer-header__actions {
  width: 100%;

  .desktop-only {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}

.layer-page {
  .search-box {
    position: static;
    display: block;
    margin-left: 0;

    .one-line-form__input {
      width: calc(100% - 5rem);
      min-width: 0;
    }

    .one-line-form__button {
      width: 5rem;

      svg {
        color: $white;
        fill: $white;
      }

      .labelled-icon__icon {
        margin-right: 0;
      }

      .body {
        display: none;
      }
    }
  }
  .facet {
    width: 80%;
    justify-content: space-between;
  }
}

@media screen and (min-width: $desktopBreakpoint) {
  .products-push .layer-products__element--4 {
    width: 25%;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .products-push .layer-products__element--4 {
    width: 100%;
  }
}

.section__head {
  margin-top: 2rem;
}

.navigation__item {
  .selector .button {
    @extend .language-changer;

    border: 0;
    margin-left: 10px;
  }

  .selector__item {
    .button {
      border: 0;
      display: block;
    }
  }
}

.modal__body--opened {
  #app {
    filter: blur(8px);
  }
}

.mini-cart-item .form {
  .form-item__label label {
    font-size: 1rem;
    margin-left: 0;
  }
}

.mini-cart-total {
  &__minimum-order-amount {
    margin-top: 0.5rem;
    font-size: 1rem;
    color: $red;
  }
}

div.explained-call-to-action__content {
  max-width: 50em;
}

.navigation__sub__content {
  .search-box {
    display: block;
    top: 0;
  }

  .button {
    * {
      color: $white;
    }
  }
}

.mini-cart-item__content {
  .form-item__label label {
    margin-left: 0;
  }
}

div.alert {
  border-radius: 0;
}

.cart-item-details__options {
  .button {
    margin-top: 0.5rem;
  }
}

div.spinner::before,
span.spinner::before {
  border-top-color: $blue;
}

.stepped-progress__item--selected {
  path {
    color: $white;
  }
}

.product-view {
  .number-input--small .number-input__input input {
    height: 3.6rem;
  }
}

.cart-content {
  .cart-item-actions {
    button {
      border: 0;
    }
  }
}

.modal__overlay {
  .modal__content {
    .h3 {
      font-size: 1.3rem;
      font-weight: 600;
    }

    a {
      + a {
        display: inline-block;
        margin-left: 1rem;
      }
    }

    .h2 {
      font-size: 1.1rem;
      font-weight: 600;
    }

    .product-added__botanicalname {
      font-size: 1rem;
      color: #888;
    }

    .product-added__options {
      margin-top: 1rem;
    }

    .price {
      font-size: 1.1rem;
    }

    .button--default {
      text-transform: uppercase;
    }

    .button--link {
      display: inline-block;
      border: 0;
      padding: 0;
      font-weight: 600;
      font-size: 1rem;
      text-decoration: none;
      text-transform: initial;
      margin-left: 0;
    }
  }
  .modal__close {
    padding: 0.5rem;
  }
}

.legal {
  font-size: 0.8rem;
  margin-top: 0.7rem;
}

.modal__overlay {
  pointer-events: none;
  overflow: hidden;

  .modal {
    pointer-events: auto;
    overflow: auto;
  }
}

.document {
  &__type {
    margin: 0 0.3rem;
    font-weight: 600;
  }
}

.cart-sidebar__rassurance {
  margin-bottom: 1rem;

  svg {
    margin-right: 0.3rem;
    display: inline-block;
  }
}

.shipping-select {
  p:first-child {
    font-weight: 600;
  }
}

.intro-documents a {
  display: inline-block;
  margin-left: 0.3rem;
}

.feature__description {
  p {
    margin-top: 0;
  }
}

.grid-cards {
  justify-content: flex-start;

  .product-item {
    padding: 1.2rem;
    flex-basis: 33%;
  }
}

.axeptio_mount {
  opacity: 0;
  transition: opacity 500ms;
}

// Dirty ID and important (mandatory)
#axeptio_main_button {
  bottom: 0 !important;
  left: 0 !important;
  height: 50px !important;
  width: 50px !important;
  opacity: 0 !important;
}

#axeptio_btn_acceptAll {
  color: $white !important;
  background: $blue !important;
}

.axeptio-widget--open .axeptio_mount,
.axeptio_mount.visible {
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.3);
}


.axeptio-toggler {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  padding: 1rem;
  background: $white;
  color: $blue;
}

.o_livechat_button {
  opacity: 0;
  max-width: 50px;
  bottom: 0;
  display: block !important;
}




.odoo-toggler {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 50;
  padding: 0.5rem;
  background: $blue;
  color: $white;
  pointer-events: none;
  opacity: 0;

  &.visible {
    opacity: 1;
  }

  svg {
    color: $white;
    fill: $white;
  }
}

.o_thread_window_header {
  background-color: $blue !important;
  color: $white !important;
  height: initial !important;
  
  .o_thread_window_title {
    color: $white !important;
  }
}

@media screen and (min-width: $tabletBreakpoint) {
  .o_livechat_button {
    opacity: 0;
    max-width: 50px;
    bottom: 15% !important;
    display: block !important;
  }
  
  .o_thread_window {
    bottom: 15% !important;
  }
  
  .odoo-toggler {
    bottom: 15%;
  }
}

.paginated-page {
  max-width: 90%;
  margin: 0 auto;
}

.product-overview__name {
  .link.link--block  {
    min-height: 60px;
    display: block;
  }
}

.menu-main-categories__item .link--active {
  text-decoration: underline;
}

.home {
  .alert__content {
    background: rgba(77, 134, 55, 0.9);
    padding: 20px;
    position: fixed;
    top: 150px;
    bottom: initial;
    right: 30px;
    width: inherit;
    color: #fff;
    font-size: 17px;

    * {
      color: #fff;
    }
  }
}

.layer-page {
  > .alert__content {
    background: rgba(77, 134, 55, 0.9);
    padding: 20px;
    position: fixed;
    top: 150px;
    bottom: initial;
    right: 30px;
    width: inherit;
    color: #fff;
    font-size: 17px;

    * {
      color: #fff;
    }
  }
}

.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.button--icon-secondary {
  border: 0;
  padding: 0;
  background: transparent;

  svg {
    fill: #066dc6;
  }
}

.order-pdfs a {
  display: inline-block;
  margin: 0 5px;
}

.fog-filter {
  #tabs-0--panel-documents {
    filter: blur(6px);
    pointer-events: none;
  }

  > .h3 {
    filter: blur(0);
  }
}

.item--inactive {
  opacity: 0.5;

  > * {
    pointer-events: none;
  }
}

.mini-cart-item__remove > .button  {
  * {
    color: #333;
  }
  
  svg {
    display: block;
    height: 3rem;
    width: 3rem;
  }
}