.form {
  &--register {
    .form-item {
      margin-top: $boxSizeMargin;

      &--help {
        margin-top: $boxSizeMargin * 2;
        margin-bottom: $boxSizeMargin * 1;
      }
    }
  }
}
