@use "sass:math";

.category-navigation {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: -$boxSizeMargin;
    width: $boxSizeMargin;
    z-index: 1;
    background: linear-gradient(
      90deg,
      rgba($white, 1) 0%,
      rgba($white, 0.75) 33%,
      rgba($white, 0.4) 67%,
      rgba($white, 0) 100%
    );
  }
  &::after {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    right: -$boxSizeMargin;
    width: $boxSizeMargin;
    z-index: 1;
    background: linear-gradient(
      270deg,
      rgba($white, 1) 0%,
      rgba($white, 0.75) 33%,
      rgba($white, 0.4) 67%,
      rgba($white, 0) 100%
    );
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    max-width: calc(100% + #{$boxSizeMargin * 2});
    margin: math.div(-$boxSizeMargin, 2) (-$boxSizeMargin);
    padding: 0;
    list-style: none;
    font-size: $mediumSize;
  }

  &__image {
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.5s;

    &::after {
      content: '';
      background: rgba(0,0,0,.4);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__element {
      position: relative;
      width: calc(50% - #{$boxSizeMargin * 2});
      padding: 1.8rem;
      margin-top: 2.4rem;
      overflow: hidden;

      &:nth-child(1),
      &:nth-child(2) {
          margin-top: 0;
      }

      &:nth-child(2n) {
        margin-left: $boxSizeMargin;
      }

      &:nth-child(2n + 1) {
        margin-right: $boxSizeMargin;
      }

      &:hover {
        .category-navigation__image {
          transform: scale(1.1);
        }
      }

      .h2 {
          margin-top: 0;
          margin-bottom: 1.2rem;
          color: $white;
          font-weight: 600;
          z-index: 2;
          position: relative;
      }

      a {
          color: $white;
          border: 1px solid $white;
          padding: 0.7rem 1rem;
          display: inline-block;
          z-index: 2;
          position: relative;
          transition: all .5s;

          &:hover {
            text-decoration: none;
            background-color: $white;
            color: $black;
  
          }
      }
  }

  @media screen and (max-width: $tabletBreakpoint) {
    &__element {
        width: 100%;
        padding: 1.8rem;
        margin-top: 1.2rem;

        &:nth-child(1),
        &:nth-child(2) {
            margin-top: 1.2rem;
        }

        &:nth-child(2n) {
            margin-left: 0;
        }

        &:nth-child(2n + 1) {
            margin-right:0;
        }
    }
  }
}
