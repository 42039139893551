$brandPrimary: #fbb03b;
$brandSecondary: #818199;
$white: #fff;
$black: #000;
$blue: #002c52;
$lightgrey: #f2f2f2;
$darkgrey: #262626;

$red: #ee0000;

$shade01: $white;
$shade02: #e9e9e9;
$shade03: #d8d8d8;
$shade04: #737373;
$shade05: #333333;
$shade06: $black;

$success: #22aa50;
$info: #fbb03b;
$warning: #ee0000;
$error: #ee0000;

$invertedSuccess: #4ed17b;
$invertedInfo: #fbb03b;
$invertedWarning: #ff9a9a;
$invertedError: #ff9a9a;

$divider: $shade02;
$blockBackground: $white;
$callToAction: $brandPrimary;

$fontDisabled: $shade05;
$fontSecondaryColor: $shade05;
$titleColor: $brandPrimary;

$backgroundColor: $white;
$fontColor: #131433;
$reverseBackgroundColor: $fontColor;
$reverseFontColor: $backgroundColor;

$buttonPrimaryColor: $brandPrimary;
$buttonSecondaryColor: $white;

$callToActionColor: $buttonSecondaryColor;
$callToActionBackground: $buttonPrimaryColor;

$hoverableColor: $brandPrimary;

$iconColor: $brandPrimary;
$notificationColor: $white;
$notificationBackground: $brandPrimary;

$modalColor: $black;
$modalBackground: $backgroundColor;
$modalBorderColor: $shade04;

$productBackground: $backgroundColor;
$productColor: $black;

$productPriceColor: $fontSecondaryColor;
$promotionBorderColor: $brandSecondary;

$optionBackground: $shade03;
$optionColor: $black;
$optionItemBackground: $white;
$optionItemBorderColor: $shade04;
$optionItemColor: $fontDisabled;
$optionItemActiveBorderColor: $shade04;

$inputBorderColor: $shade04;

/* export variables used in `Colors.story.js` */
:export {
  brandprimary: $brandPrimary;
  brandsecondary: $brandSecondary;

  shade01: $shade01;
  shade02: $shade02;
  shade03: $shade03;
  shade04: $shade04;
  shade05: $shade05;
  shade06: $shade06;

  uisuccess: $success;
  uiinfo: $info;
  uiwarning: $warning;
  uierror: $error;

  text-black: $black;
  text-white: $white;
}
