.listing-header__description {
  .container {
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .breadcrumb {
    margin: 0 0 1rem;
    padding: 0;

    &__item {
      color: #959393;

      a,
      span,
      path {
        color: #959393;
      }
    }
  }
}
