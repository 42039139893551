$tabletBreakpoint: 35em;
$menuBreakpoint: 56em;
$mobileBreakpoint: 25em;
$desktopBreakpoint: 75em;

// Inspired from Bootstrap
// https://github.com/twbs/bootstrap/blob/cb4bc89fdf0286e9b88b6d5be6148b9b53463ff8/scss/mixins/_breakpoints.scss#L52
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@media screen and (max-width: #{$menuBreakpoint - 0.01em}) {
  .desktop-only {
    display: none !important;
  }
}

@media screen and (min-width: $menuBreakpoint) {
  .mobile-only {
    display: none !important;
  }
}
