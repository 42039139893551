.header {
  background: $white;
  border-bottom: 1px solid $divider;
  position: relative;
  z-index: 3;

  &__container {
    min-height: 7em;
    display: flex;
    align-items: center;
    justify-content: stretch;

    @media screen and (min-width: $menuBreakpoint) {
      & {
        min-height: 8em;
      }
    }
  }

  &__central {
    margin: 0 $boxSizeMargin / 2;
    flex: 1;
    display: flex;
    justify-content: center;
  }

  &__sub {
    position: relative;
    z-index: 2;
  }

  &__store {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    > * {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    &:empty {
      display: none;
    }
  }

  @media screen and (min-width: $menuBreakpoint) {
    &__logo {
      flex: 1;
    }
    &__central {
      flex: 5;
    }
    &__links {
      flex: 1;
    }
  }
}

@media print {
  .header {
    border: 0;

    &__container {
      min-height: unset;

      > * {
        display: none;
      }
    }
    &__logo {
      display: block;
    }
  }
}

@import "~./Navigation/Navigation";

.header {
  border: none;
  position: fixed;
  width: 100%;

  &--static {
    position: static;
  }
}
.header__container {
  min-height: 12em;
  transition: all 0.3s ease;
}
.main-content {
  padding-top: 12em;
}
.container.header__container {
  max-width: 98%;
}
.menu-main-categories__item {
  font-weight: 400;
  font-size: 1.3rem;
  position: relative;
  margin: 0 1.2rem;
  padding: 0.6rem 0;

  &::before {
    content: '';
    height: 1px;
    background-color: $black;
    position: absolute;
    bottom: 10px;
    left: 0%;
    width: 100%;
    transform: translateY(-2px);
    transition: all .5s;
    opacity: 0;
  }

  &:hover {
    .categories-menu__container {
      opacity: 1;
      pointer-events: auto;
    }
    &::before {
      transform: translateY(0);
      opacity: 1;
    }
  }

  a:hover {
    text-decoration: none;
  }

  .button {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: normal;
    font-size: 1.3rem;

    &:hover,
    &:focus {
      background: transparent;
      text-decoration: none;
    }
  }
}
.categories-menu__list {
  flex-basis: 50%;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 0px;

  .link-list {
    padding: 0;
    margin: 0;
  }

  .link-list__title {
    margin-bottom: 0;
  }

  .link-list__list {
    margin: 0;
  }

  
  .link-list__title {
    color: $black;
    border-bottom: 0;
  }

  a {
    display: block;
    padding: .75rem 2rem;
  }
}
.header__links .navigation__list {
  align-items: center;
}

.header__logo img {
  width: 200px;
  transition: width 0.3s ease;
}
.header.sticky .header__container {
  min-height: 5rem;
}
.header.sticky .header__logo img {
  width: 150px;
}
.language-changer {
  display: flex;
  align-items: center;
  font-size: 1rem;
}
.language-changer__link {
  margin-right: 0.5rem;
}
.menu__search {
  list-style-type: none;
  display: flex;
  position: relative;
}
.menu__search .button:hover {
  background: transparent;
}
.search-box {
  display: none;
  margin-left: 1rem;
  position: absolute;
  top: -25px;
  left: 2rem;
}
.search-box.search-box__open {
  display: block;
}
.search-box__form input {
  background-color: transparent;
  border-bottom: 1px solid #aeaeae;
}
.search-box__form .one-line-form__input {
  min-width: 25ch;
}

.header__sub {
  z-index: 5;
}

@media screen and (max-width: 56em) {
  .header__mobile-expanded.expanded {
    display: block;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 80vh;
    background-color: #fff;
  }
  .header__mobile-expanded.expanded::after {
    content: "";
    background-color: $blue;
    width: 100%;
    height: 150px;
    position: absolute;
    bottom: 0;
  }
  .expanded__header {
    display: flex;
    padding: 1rem;
  }
  .expanded__header .header__logo {
    margin: auto;
    transform: translateX(-1rem);
  }
  .search-box-mobile {
    top: 3rem;
    background-color: #fff;
    width: 100%;
    left: 0;
    margin-left: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .menu-nav-mobile .link-list__item {
    padding: 1.5rem 0;
  }
  .menu-nav-mobile .link-list__item a {
    font-size: 1.4rem;
  }
  .container.header__container {
    min-height: 5rem;
    justify-content: space-between;
    padding: 0 0.5rem;
  }
  .header__logo img {
    width: 130px;
  }
  .header.sticky .header__logo img {
    width: 130px;
  }
}

.selector {
  .body {
    text-transform: uppercase;
  }
}

.categories-menu__container {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all .5s;
  pointer-events: none;
  width: initial;
  background-color: $white;
  z-index: 2;
  padding: 0;
  box-shadow: 0 5px 23px -8px #d7d7d7;
  

  .categories-menu {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    margin: 0;
    background-color: $white;
    color: $black;
    transition: all .5s;
    text-align: left;
    z-index: 3;

    .categories-menu__list {
      min-width: 0;
      background-color: $white;
      color: $black;

      &:hover {
        background-color: $blue;
        color: $white;

        a {
          color: $white !important;
        }
      }
    }
  }

  .link-list {
    margin-right: 0;
  }
}

.navigation__sub--opened .navigation__sub__content .stores {
  padding: 0.8rem 0.3rem;

  .selector {
    margin: 0;
    border: 0;

    > .button {
      display: none;
    }

    &__list {
      display: flex;

      .button {
        border: 0;

        &--disabled {
          display: none;
        }
      }
    }

    &__item {
      margin-right: 1rem;
    }
  }
}

.header__minimum-order-amount {
  background-color: $blue;
  color: $white;
  padding: 0.5rem 1rem;
}

.header__minimum-order-amount-content {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    color: $white;
  }  

  span {
    display: inline-block;
    color: $white;
  }
}
