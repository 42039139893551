.hero-header {
  min-height: 55rem;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: -12rem;
  padding: 16rem 5% 5%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;


  .background-image::after {
    opacity: 0;
  }

  + .highlighted-content {
    padding-top: 0;
  }
}
.hero-header__content {
  position: static;
  width: 100%;
  margin-top: 5rem;
}
.hero-header__title {
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.2;
}
.hero-header__title span {
  color: #fff;
}
.hero-header__text {
  font-size: 1.2rem;
}

@media screen and (min-width: 56em) {
  .hero-header {
    min-height: 90vh;
    padding: 5%;
    flex-direction: row;

    &__title {
      line-height: 1.4;
    }

    &__text {
      max-width: calc(100% - 100px);
    }

    &__content  {
      width: 90%;
      margin-top: 0;

    }

    .responsive-image--cover .responsive-image__actual,
    .responsive-image--cover .responsive-image__placeholder {
      object-fit: unset;
    }
  }
}

@media screen and (min-width: $menuBreakpoint) {
  .hero-header__content {
    margin-top: 40vh;
  }

  .hero-header__content--right {
    text-align: right;

    .hero-header__text {
      margin-left: auto;
    }
  }
}

.hero-header__text p {
  color: #fff;
}
.hero-header__button {
  margin-top: 4rem;
}
.hero-header__button button {
  padding: 0.95rem 3.8rem 0.85rem;
}
.hero-header__sirius {
  position: relative;
  top: 2rem;
  right: 7%;
  border: 1px solid #fff;
}
.sirius__img {
  padding: 0.5rem;
}
.sirius__link {
  position: relative;
  text-align: center;
  color: #fff;
  border-top: 1px solid #fff;
  transition: all 0.3s ease;

  a {
    padding: 0.63rem;
    display: block;
  }
}
.sirius__link svg {
  position: absolute;
  width: 42px;
  fill: #fff;
  bottom: -1px;
  right: -42px;
}
.sirius__link:hover {
  background-color: #fff;
  color: #000;
}
.sirius__link:hover a {
  text-decoration: none;
}
