.bottombar {
  border-top: 1px solid $divider;
  padding-top: $boxSizeMargin * 2;
  width: 100%;

  &__aside {
    margin-bottom: $boxSizeMargin * 2;
  }
  margin-bottom: $boxSizeMargin * 2;

  ul {
    padding-left: 0;
    list-style-type: none;
    margin: 0.6rem 0;
  }

  li {
    position: relative;
    padding: 0.3rem 0;
    margin: 0;
    line-height: 1;
  }
}

@media screen and (min-width: #{$menuBreakpoint}) {
  .bottombar {
    border-top: none;
    padding-top: 0;

    &__wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &__aside {
      flex-grow: auto;
      margin-right: $boxSizeMargin * 2;
      min-width: $boxSizeMargin * 20;

      p {
        max-width: 45ch;
      }
    }

    &__links {
      display: flex;
      justify-content: flex-start;
    }

    &__list {
      width: 20%;
      min-width: $boxSizeMargin * 10;
      margin: 0 $boxSizeMargin;
    }

    &__socials {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
    }

    li {
      display: block;
    }

    a {
      overflow: hidden;
      padding-bottom: $boxSizeMargin * 0.2;
      position: relative;
      display: inline-block;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        transform: translateX(-100%);
        bottom: 0;
        transition: transform 500ms;
        height: 1px;
        background-color: $black;
        width: 100%;
      }

      &:hover {
        text-decoration: none;

        &::before {
          transform: translateX(0);
        }
      }
    }
  }
}

@media print {
  .bottombar {
    display: none;
  }
}

.bottombar {
  background-color: $lightgrey;
  border-top: 3px solid $blue;
  margin-bottom: 0;
  padding: 2.4rem 0;
}
.bottombar__list {
  width: 50%;
}
.bottombar__links {
  flex: 2;
}
.bottombar__center {
  flex: 2;
  align-self: center;
}
.bottombar__logo {
  text-align: center;

  a {
    &::before {
      content: none;
    }
  }
}
.bottombar__logo img {
  max-width: 200px;
}
.link-list {
  padding-right: 3rem;
}
.link-list__title {
  text-transform: none;
  color: $blue;
  font-weight: normal;
  border-bottom: 1px solid #afafaf;
}
.brand__social-media {
  border-top: 1px solid #afafaf;
}
.brand__social-media .icon-list svg path {
  fill: $blue;
}
@media screen and (max-width: 35em) {
  .bottombar__list {
    width: 100%;
    text-align: center;
  }
  .link-list {
    padding: 0;
  }
  .link-list__title {
    font-weight: 500;
    font-size: 1.5rem;
    border: none;
  }
  .brand__social-media {
    border: none;

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .icon-list {
    justify-content: center;
  }
}
