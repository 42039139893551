.footer {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  color: $black;
  background-color: $lightgrey;
  border-top: 3px solid $blue;
  position: relative;
  z-index: 4;

  h3 {
    color: $blue;
    border-bottom: 1px solid $black;
  }
}

.footer-legislation {
  position: fixed;
  bottom: 20px;
  right: 20px;

  .button--primary .labelled-icon__content {
    color: #fff;

    * {
      color: #fff;
    }
  }
}

@import "~./BottomBar";
