form {
  .checkbox label {
    padding-left: 2.2rem;
  }
  .payzen-form-input,
  input,
  select {
    border-radius: 0;
    background-color: $lightgrey;
    padding: 0 0 0 1rem;
  }
  textarea {
    border-radius: 0;
    background-color: $lightgrey;
    padding: 1rem;
  }
  .form-item__label label {
    font-weight: 500;
    font-size: 1.2rem;
    margin-left: -1.4rem;
  }

  .form-item {
    &--checkbox {
      padding-left: 25%;

      .form-item__input {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .radio > label {
    padding-left: 2.2rem;
  }
  .radio [type="radio"].payzen-form-input + label:before,
  .radio input[type="radio"] + label:before {
    width: 1.2rem;
    height: 1.2rem;
    border: 1px solid #000;
    top: 20%;
  }
  .radio [type="radio"].payzen-form-input:checked + label:before,
  .radio input[type="radio"]:checked + label:before {
    content: "•";
    background-color: #fff;
    color: $blue;
    font-size: 1.7rem;
    line-height: 0.7rem;
    border: 1px solid #000;
    border-radius: 50%;
  }
  .radio [type="radio"].payzen-form-input:focus + label:after,
  .radio [type="radio"].payzen-form-input:hover + label:after,
  .radio input[type="radio"]:focus + label:after,
  .radio input[type="radio"]:hover + label:after {
    width: calc(1.2rem - 2px);
    height: calc(1.2rem - 2px);
    border: 2px solid $blue;
    top: 25%;
  }
  .payzen-form-input,
  input,
  select {
    height: 3.5rem;
  }
  .number-input {
    border-radius: 0;
    border: 1px solid #000;
    min-width: 3.6rem;
  }
  .number-input__input > * {
    width: 3ch;
    font-size: 1.6rem;
  }
  .checkbox [type="checkbox"].payzen-form-input + label::before,
  .checkbox input[type="checkbox"] + label::before {
    border-color: #707070;
    width: 1.6rem;
    height: 1.6rem;
    top: 3px;
  }
  .checkbox
    [type="checkbox"].payzen-form-input:not(:disabled):focus
    + label::before,
  .checkbox
    [type="checkbox"].payzen-form-input:not(:disabled):hover
    + label::before,
  .checkbox input[type="checkbox"]:not(:disabled):focus + label::before,
  .checkbox input[type="checkbox"]:not(:disabled):hover + label::before {
    outline-color: $blue;
  }
  .checkbox [type="checkbox"].payzen-form-input:checked + label::before,
  .checkbox input[type="checkbox"]:checked + label::before {
    font-size: 1.1rem;
    line-height: 21px;
  }
  .radio [type="radio"].payzen-form-input:focus + label::after,
  .radio [type="radio"].payzen-form-input:hover + label::after,
  .radio input[type="radio"]:focus + label::after,
  .radio input[type="radio"]:hover + label::after {
    top: 23.5%;
  }
  .select-input {
    border-radius: 0;
    border: 1px solid #000;
  }
  .select-input > select {
    padding: 0.1rem 4rem 0 0.5rem;
    color: #646464;
    font-weight: 200;
  }
  .select-input > :last-child {
    right: 0.6rem;
  }

  .form-item--checkout-ref {
    label {
      margin-left: 0;
    }
    .form-item__input {
      width: 100%;
      max-width: 400px;
    }
  }

  &.form--register {
    .fieldset {
      display: flex;
      flex-direction: column;

      .register__social-reason {
        order: 2;

        .form-item__input {
          position: relative;

          &__recommandation {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            border: 1px solid $black;
            list-style-type: none;
            z-index: 2;
            background: white;
            padding: 10px 0;

            li {
              list-style-type: none;
              cursor: pointer;
              padding: 3px 10px;

              &:hover {
                background-color: $blue;
                color: $white;
              }

              &.recomandation--focused {
                background-color: $blue;
                color: $white;
              }
            }

            
          }
        }

        
      }
      .register__country {
        order: 3;
      }
      .register__autocomplete {
        margin-top: 20px;
        margin-bottom: 30px;
        order: 4;
      }

      .alert {
        order: 1;
      }

      > div {
        order: 99;

      }
    }
  }
}

.modal__overlay .modal__content .register__autocomplete .button--default {
  text-transform: initial;
}

.formulaire-content .form-item--double .form-item__input {
  width: 33.9%;
  min-width: 33.9%;
  margin-right: 1rem;
}
.form-item--double .form-item__input > * {
  margin-top: 0.6rem;
}
.formulaire-content .form-actions {
  display: block;
  padding-left: 25%;
}
.formulaire-content .button {
  padding: 0.95rem 5rem 0.85rem;
  font-weight: 400;
}
.add-to-cart__main {
  align-items: flex-start;
}

.form-item__tooltip {
  position: relative;
  margin-left: 1rem;
  border: 1px solid #000;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0.3rem 0.6rem;
  font-size: 1.3rem;
  line-height: 1.3rem;
}

.autocomplete__tooltip {
  position: relative;
  margin-left: 1rem;
  border: 1px solid #000;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0.3rem 0.6rem;
  font-size: 1.3rem;
  line-height: 1.3rem;
}

.form-item__tooltip:hover .tooltip__content,
.autocomplete__tooltip:hover .tooltip__content {
  display: block;
}

.tooltip__content {
  display: none;
  position: absolute;
  width: 20rem;
  background-color: #fff;
  border: 1px solid #000;
  font-size: 1rem;
  padding: 1rem;
  right: -21rem;
  top: -0.6rem;
}
.formulaire-content .fieldset {
  max-width: 45em;
  margin: 0;
}
.formulaire-content .form-item {
  flex-direction: row;
  align-items: center;
}
.formulaire-content .form-item__label {
  width: 30%;
  min-width: 30%;
  margin-right: 7px;
}
.formulaire-content .form-item__label label {
  margin-left: 0;
  font-weight: 400;
  font-size: 1.1rem;
}
.formulaire-content .form-item__input {
  width: 70%;
  min-width: 70%;
}

.multistep {
  .multistep__current-step {
    &.step-2,
    &.step-3 {
      .radio-grid {
        display: block;

        &__element {
          width: 100%;
          max-width: inherit;
          border: 0;
          justify-content: flex-start;
          text-align: left;

          input {
            position: static;
            opacity: 1;
            width: inherit;
            width: 40px;
          }
        }
      }

      .item-with-addon {
        max-width: 100%;
        width: 100%;
        align-items: flex-start;
      }
    }

    &.step-3 {
      .item-with-addon {
        max-width: 100%;
        width: 100%;
        align-items: flex-start;
        flex-direction: row-reverse;
        justify-content: flex-end;

        &__addon {
          margin-right: 1rem;
        }
      }
    }
  }

  .button {
    margin-top: 1rem;
  }

  .gsc {
    .button {
      padding: 0 1rem;
      border: 0;
      margin-top: 0;
    }
  }
}

form {
  input,
  .payzen-form-input,
  select,
  textarea {
    border-radius: 0;
  }
}

div.formulaire-content .form-item--double {
  .form-item__input {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 70%;

    .input-wrapper {
      width: calc(50% - 5px);
      align-self: flex-start;
    }
  }
}

form .input-wrapper__error {
  padding-left: 0;
  padding-right: 0;
  font-size: 0.9rem;
}

.modal .formulaire-content .form-item {
  flex-direction: column;

  > * {
    width: 100%;
  }

  &--double .form-item__input {
    width: 100%;
    margin-right: 0;
  }
}

div.radio-grid--hasValue .radio-grid__element--checked {
  border: 1px solid $blue;
  border-radius: 0;
}

.multistep {
  .radio-grid__element {
    border-radius: 0;
  }
}

@media screen and (max-width: $menuBreakpoint) {
  div.form-item__link {
    margin-top: 0;
  }
  .form-item__label label {
    margin-left: -1.7rem;
  }

  .formulaire-content .form-item {
    flex-direction: column;
  }
  .formulaire-content .form-item__label {
    width: auto;
    min-width: auto;
  }
  .formulaire-content .form-item__input {
    width: 100%;
    min-width: 100%;
  }
  .formulaire-content .form-item--double .form-item__input {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
  }
  .formulaire-content .form-actions {
    display: flex;
    padding-left: 0;
  }
  .form-item--tooltip .form-item__label {
    order: 1;
  }
  .form-item--tooltip .form-item__tooltip {
    order: 2;
  }
  .form-item--tooltip .form-item__input {
    order: 3;
  }
  .tooltip__content {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    top: 2rem;
  }
}

form {
  .form-item {
    position: relative;

    &__tooltip {
      position: absolute;
      width: 2rem;
      height: 2rem;
      right: -3rem;
      top: 0.8rem;
    }
  }

  .tooltip__content {
    z-index: 3;
  }
}

.autocomplete__tooltip {
  position: absolute;
  right: -3rem;
  top: 0.8rem;
}

.formulaire-content .form-actions {
  padding-left: 0;
  margin-top: 1rem;
  max-width: 45em;
  text-align: right;

  .form-actions__element {
    margin: 0.6rem;
  }
}

.modal__content {
  .grid {
    flex-direction: column;
  }

  .tooltip__content {
    right: 2rem;
    z-index: 2;
  }

  form .form-item__tooltip {
    right: -3rem;
    top: 2.8rem;
  }

  form .autocomplete__tooltip {
    right: -3rem;
    top: 50%;
    transform: translateY(-50%);
  }

}

.explanation {
  font-size: 0.9rem;
  margin-top: 0.5rem;
  display: block;
}

.intro-password-request {
  margin-bottom: 1rem;
}


.password {
  position: relative;

  &__show-icon {
    position: absolute;
    right: -40px;
    top: 50%;
    transform: translateY(-50%);
    height: inherit;
  }
}

input[disabled] {
  + label {
    opacity: .3;
  }
}
