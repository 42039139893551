.landing-header {

    .header__logo,
    .landing-header__store-selector {
        min-width: 200px;
        max-width: 200px;
        flex: 0;
        display: block;
    }

    .landing-header__central {
        flex: 2;
        font-size: 20px;
        text-align: center;
    } 

    .selector {
        text-align: right;
    }

    .button {
        border: 0;
        max-width: 100px;
        margin-left: auto;
    }

    + .main-content {
        padding-top: 0;
    }
}
.landing-welcome {
    display: flex;
    margin-bottom: -4.9rem;
    font-size: 18px;
    line-height: 1.4;

    &__content {
        flex: 1;
    }
    &__image {
        position: relative;
        flex: 1
    }

    &__top-text {
        padding: 2rem 4rem;
        background: $white;

        &.blue {
            background: $blue;
            color: $white;

            p {
                color: $white;
            }
        }
    }

    &__text {
        padding: 2rem 4rem;
        background-color: $white;
    }

    &__button {
        margin-top: 3rem;
    }

    &__image {
        min-height: 36vw;
    }

    @media screen and (max-width: 56em) {
        flex-direction: column-reverse;
        font-size: 14px;
        margin-bottom: 0;

        &__top-text,
        &__text {
            padding: 2rem;
        }
    }
}