.highlighted__grid {
  display: flex;
}
.highlighted__cell {
  flex: 1;
  display: flex;
  flex-direction: collumn;
  align-items: center;
}
.highlighted__cell img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.highlighted__description {
  padding: 2rem 5rem;
}
.highlighted__description button {
  margin-top: 2rem;
  padding: 0.95rem 3.8rem 0.85rem;
}
.highlighted__cell--left {
  text-align: right;
  justify-content: end;
}
.highlighted__img {
  display: flex;
  justify-content: space-around;
}
.highlighted__img img {
  max-width: 125px;
  margin-left: 3rem;
}
.highlighted__title {
  position: relative;

  &:not(.title-blue) {
    margin-bottom: 1.4em;
  }
}
.highlighted__decor {
  position: absolute;
  z-index: 3;
  width: 417px;
  top: 25px;
  left: -275px;
}
.highlighted__cell--left .highlighted__decor {
  left: auto;
  right: -275px;
}
.highlighted__decor--white svg {
  fill: #fff;
}
.avis-client {
  text-align: center;
  padding: 0 3rem;
}
.avis-client__stars {
  display: flex;
  justify-content: center;
}
.avis-client__stars .star {
  margin: 0 0.2rem;
}
.avis-client__title {
  margin-bottom: 2rem;
}

.avis-client__title span {
  font-size: 1.3rem;
  font-weight: 500;
}

.wysiwyg {
  .button {
    &--full-width {
      text-align: center;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .h2--strong {
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.products-push {
  .product-item__actions {
    position: static;
  }
}

.highlighted-content {
  .responsive-image--full .responsive-image__actual,
  .responsive-image--full .responsive-image__placeholder,
  .responsive-image--full-rounded .responsive-image__actual,
  .responsive-image--full-rounded .responsive-image__placeholder {
    object-fit: cover;
  }
}
.products-push {
  margin-bottom: 2rem;
}

.products-push,
.section.reviews,
.highlighted-content {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.highlighted-content {
  + .products-push {
    text-align: center;

    .h1 {
      margin-bottom: 4.8rem;
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 56em) {
  .hero-header__content {
    text-align: center;
    left: auto;
    top: 35%;
    padding: 0 1rem;
  }
  .hero-header__title {
    font-size: 1.8rem;
  }
  .hero-header__text {
    max-width: 100%;
  }
  .highlighted__grid {
    flex-direction: column;
  }
  .highlighted__description {
    padding: 1rem 2rem;
    text-align: center;
  }
  .highlighted__decor {
    width: auto;
    left: 0;
    right: 0;
    margin: auto;
    top: auto;
    bottom: 0;
  }
  .highlighted__cell--left .highlighted__decor {
    width: auto;
    left: 0;
    right: 0;
    margin: auto;
    top: auto;
    bottom: 0;
  }
  .highlighted__title {
    padding-bottom: 2rem;
  }
  .highlighted__description button {
    margin: 1rem 0;
    padding: 0.95rem 3rem 0.85rem;
  }
  .highlighted__cell--left {
    order: 2;
  }
  .highlighted__img {
    flex-wrap: wrap;
  }
  .highlighted__img img {
    max-width: 40%;
    width: 40%;
    margin-left: 1rem;
    margin-bottom: 2rem;
  }
  .hero-header__content {
    width: 100%;
  }

  .highlighted__description {
    width: 100%;
  }
}
