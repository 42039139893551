$boxSizeMargin: 1.2rem;
$smallContainerWidth: 40rem;
$mediumContainerWidth: 74.9375rem;
$containerWidth: 95.5rem;
$blue: #002c52;
$black: #373737;
$lightgrey: #f2f2f2;
$white: #fff;

@import "~./normalize";

body {
  margin: 0;
}

.ReactModal__Overlay--after-open {
  z-index: 40;
}

.ReactModal__Body--open {
  overflow: hidden;
}

@import "~theme/components/components";
@import "~theme/layouts/Layout";
@import "~theme/modules/modules";
@import "~theme/pages/pages";
